import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  FormHelperText,
  DialogContent,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import { toast } from "react-hot-toast";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { postAPIHandler } from "src/ApiConfig/service";

const useStyle = makeStyles((theme) => ({
  PlaceBidModalBox: {
    padding: "20px",
    paddingBottom: "8px",
    "& h5": {
      color: "#FFF",
      fontSize: "23px",
      fontWeight: "500",
    },
  },
  maindialog: {
    "& .MuiDialogContent-root": {
      padding: "0px !important",
    },
  },
}));

export default function FreePlayLoginModal({
  handleCloseModal,
  openModal,
  auth,
}) {
  const classes = useStyle();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email:
      localStorage.getItem("freePlayEml") != "undefined"
        ? localStorage.getItem("freePlayEml")
        : "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .max(100, "Should not exceeds 100 characters.")
      .email("Please enter a valid email address.")
      .required("Email is required."),
  });

  const handleSubmitRefer = async (values) => {
    try {
      setIsLoading(true);
      const email = values?.email.match(/[^?]+$/)?.[0];
      const response = await postAPIHandler({
        endPoint: "LoginEmail",
        dataToSend: {
          email: email,
        },
      });
      console.log("response======", response);
      if (response.data.responseCode === 200) {
        toast.success(response?.data?.responseMessage);
        sessionStorage.setItem("token", response.data.result.token);
        auth.userLogIn(true, response.data.result.token);
        auth.viewUserProfile(sessionStorage.getItem("token"));
        handleCloseModal();
      } else {
        toast.error(response?.data?.responseMessage);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      maxWidth="xs"
      fullWidth
      className={classes.maindialog}
    >
      <IconButton
        style={{ position: "absolute", right: "0px" }}
        onClick={() => {
          handleCloseModal();
        }}
        disabled={isLoading}
      >
        <CgClose style={{ color: "#ffffffc7" }} />
      </IconButton>
      <Box className={classes.PlaceBidModalBox}>
        <Typography variant="h5">Login</Typography>
      </Box>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitRefer(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Box align="start">
                <Typography variant="body1">Email</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="email"
                  placeholder="Please enter email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormHelperText error>
                  {touched.email && errors.email}
                </FormHelperText>
              </Box>
              <Box align="center" my={2}>
                <Button variant="contained" color="primary" type="submit">
                  Submit {isLoading && <LoadingComp />}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
