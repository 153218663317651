import React from 'react';
import logo from '../../assets/logo.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { gameSound } from '../../../../../App';

function Logo() {
  const navigate = useHistory();
  const handleBack= () => {
    gameSound.pauseBgAudio();
    navigate.push('/');
    document.body.style.overflowY = 'auto';
  }
  return (
    <div className='w-12 h-12 md:w-16 md:h-16 cursor-pointer' onClick={handleBack}>
      <img src={logo} alt="logo" width="100%" />
    </div>
  )
}

export default Logo
