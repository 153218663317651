import React from "react";

function Token({token,SelectColor,className=""}) {
  return (
    <>
      <div
      className={`flex flex-col items-center justify-center gap-2 w-full ${className}`}
      key={token.color}
    >
      <div className="w-8 xl:w-12">
        <img src={token?.tokenImage} alt={token.color} />
      </div>
      <div
        onClick={() => SelectColor(token)}
        className={`w-8 h-8 xl:w-10 xl:h-10 border rounded-full cursor-pointer`}
        style={{
          background: token?.selected ? `${token.hash}` : "",
        }}
      ></div>
    </div>
    </>
  );
}

export default Token;
