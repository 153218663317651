
import React from 'react'
import Logo from '../Logo/Logo'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { gameSound } from '../../../../../App';


function MainNav() {
    const navigate = useHistory();
  const handleBack= () => {
    gameSound.pauseBgAudio();
    navigate.push('/');
    document.body.style.overflowY = 'auto';
  }
  return (
    <div className='bg-[#191B30]  py-2 h-18 md:h-20 fixed top-0 w-full z-10  px-4 flex justify-start items-center main-nav' style={{borderBottom : '1px solid #2C2C2C'}}>
       <span className='justify-self-start lg:flex-1'>
          <svg  className='cursor-pointer ' onClick={handleBack} width="26" height="26" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
             <path d="M17.51 3.87 15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13Z"></path>
          </svg>
       </span>
       <div className='lg:flex-1'>
         <Logo />
       </div>
    </div>
  )
}

export default MainNav
