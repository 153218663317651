import React from "react";
import Button from "./Button";
import checkIcon from "../../assets/check.png";
const CheckIcon = () => {
  return (
    <div>
      <img src={checkIcon} alt={checkIcon} className="animate" />
    </div>
  );
};

function TypeSelect({ text, selected, onSelect }) {
  return (
    <>
      <div className="p-2">
        <Button
          text={
            <div className="flex items-center gap-3 justify-start" onClick={() => onSelect(text)}>
              <div
                className="w-8 h-8 border-2 border-[#FFB900] rounded-full hover:shadow-lg hover:shadow-yellow-400/50"
              >
                {selected && <CheckIcon />}
              </div>
              <span className="text-[1rem] md:text-lg">{text}</span>
            </div>
          }
          className="bg-transparent outline outline-3 outline-[#FFB900] text-[#FFB900] text-2xl font-extrabold py-3 w-8/12 md:w-7/12 hover:transform-none"
        />
      </div>
    </>
  );
}

export default TypeSelect;
