/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import guestUser from '../../assets/ludo-avatar.png';
import CImage from '../../components/Img/CImage';
import settingIcon from '../../assets/setting.png'
// import { gameSound } from '../../utils/sound';

function Navbar(props) {
  return (
    <nav className='py-2 h-16 md:h-20 fixed top-0 right-[0%] z-10 ludo-nav'>
       <div className='nav-continer flex justify-between items-center w-full  cursor-pointer p-2'>
         <div className='flex items-center justify-start gap-5 scale-75 md:scale-100' onClick={props?.openProfileModal}>
             {/* <UserProgress /> */}
         </div>
         <div className='right flex items-center justify-center gap-x-5'>
            {/* <NavContent icon={wallet} text={"2024"} /> */}
            <CImage width="10" url={props?.profile || guestUser}/>
            <img src={settingIcon} alt={settingIcon} className='w-8' onClick={props?.openSettingModal}/>
         </div>
       </div>
    </nav>
  )
}

export default Navbar;
// w-10/12 md:w-full lg:w-4/12 xl:w-3/12 