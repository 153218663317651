
import { service_getGameDetails, service_JoinPoolApi, service_saveWinners } from "../../../../../service/poolService";



/**
 * Join Pool Api
 * @param {Object} payload 
 * @returns Promies
 */
export const JoinPoolApi = async (payload) => {
  return service_JoinPoolApi(payload)
}

//** save winners  */
export const saveWinners = async (payload) => {
  return service_saveWinners(payload)
}

// ** Get game details
export const getGameDetails = async (poolId) => {
  return service_getGameDetails(poolId);
}