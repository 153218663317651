/**
 * find out all selected states of game
 */
export const getSelecteGameState = (inGamePlayers, tokens, gameTypes) => {
  const cb = (data) => data.selected;
  const inGamePlayer = inGamePlayers.find(cb);
  const token = tokens.find(cb);
  const feeType = gameTypes.find(cb);
  return {
    token : token?.color,
    inGamePlayer : inGamePlayer?.value,
    feeType: feeType.type,
    feeAmmount: feeType.value,
  };
};

export const getRandomeColorForTowPlayers = (myColor) => {
  let color = ""
   switch(myColor) {
      case 'green': {
        color = "blue"
        break;
      }
      case 'yellow' : { 
        color = 'red'
        break;
      }
      case "blue" : {
        color = 'green'
        break;
      }
      case "red" : {
        color = 'yellow'
        break;
      }
      default: {
         console.log("invalid color")
      }
   }
   return color;
}