/* eslint-disable react/prop-types */
import React from 'react'
import Text from '../../../../components/Wrapper/Text'
import FlexWrapper from '../../../../components/Wrapper/FlexWrapper'
import Button from '../../../../components/button/Button';
import coin from '../../../../assets/coins.png'
import CImage from '../../../../components/Img/CImage';

function ListItem({ isVisible,serial, isOpen, name, players,onJoin,roomAmmount }) {
  return (
    <li
      className={`${(isVisible === false || isOpen === false || !roomAmmount) && 'hidden'} bg-[#6ca9ff6c] border border-[#e4ab0f7a] rounded-md flex justify-between items-center gap-x-2 py-3 px-2 md:px-2 my-2`}
    >
      <Text title={
      <>
       <span>
       <svg width="13" height="13" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
        <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
       </svg>  
       </span>
       <span className='lobby-name'>{name}</span>
      </>
    } color="text-white text-sm md:text-lg" />
      <FlexWrapper>
        <p className="flex gap-x-1 items-center">
          <span>
            <svg
              width="25"
              height="25"
              fill="#ffdc19"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5ZM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34ZM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12Zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7Zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44ZM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35Z"></path>
            </svg>
          </span>
          <Text title={players} color="text-[#ffffff]  text-sm md:text-xl" />
        </p>
        <p className="flex gap-x-1 items-center">
          <span>
            <CImage url={coin} width="5"/>
          </span>
          <span>
            <Text title={roomAmmount} color="text-[#ffffff] text-sm md:text-xl" />
          </span>
        </p>
        <Button text="Join" handleClick={onJoin} className='text-xs px-2'/>
      </FlexWrapper>
    </li>
  );
}

export default ListItem
