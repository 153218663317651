import React from 'react';
import logo from '../../assets/MainLogo.png'

function MainLogo() {
  return (
    <div className='logo flex flex-col justify-center items-center h-1/6 lg:h-auto py-0 md:py-4'>
       <img src={logo} alt="logo" width="auto" className='scale-[.6] xl:scale-90'  draggable={false}/>
    </div>
  )
}

export default MainLogo;
