import React, { Suspense, useContext, useEffect, useRef, useState } from "react";
// import { Outlet } from "react-router-dom";
import Navbar from "../components/Nav/Navbar";
import ProfileModal from "../components/Nav/Modal/ProfileModal";
import SettingModal from "../components/Nav/Modal/SettingModal";
import mainBg from '../assets/mainBg.png'
// import soundSrc from '../assets/sounds/bgMusic.mp3'

import MainMenu from "../screens/main/MainMenu";
import { gameSound } from "../../../../App";
import MainNav from "../components/Nav/MainNav";
import { AuthContext } from "../../../../context/Auth";

function MainLayout() {
  const [profileModalShow,setProfileModalShow] = useState(false);
  const [settingModalshow,setSettingModalshow] = useState(false); 
  const auth = useContext(AuthContext)
  

  useEffect(() => {
      gameSound.playBgAudio();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window?.location]);
 
  // profile modal functions
  const onCloseProfileModal = () => setProfileModalShow(false);
  const openProfileModal = () => setProfileModalShow(true);
   
  // setting modal functions
  const onCloseSettingModal = () => setSettingModalshow(false);
  const openSettingModal = () => setSettingModalshow(true);
  
  useEffect(() => {
    const ludoLayout = document.querySelector('.ludo-layout')
     ludoLayout.style.background =
      "radial-gradient(circle, rgba(185, 185, 189, 0.6139705882352942) 1%, rgba(76, 0, 255, 0.259) 55%), url(" +
      mainBg +
      ")";
      document.body.style.overflow = 'hidden';
  },[]);



  return (
    <>   
        <MainNav />
         <Navbar openProfileModal={openProfileModal} openSettingModal={openSettingModal} profile={auth?.userData?.profilePic}/>
         {/* <Navbar openProfileModal={openProfileModal} openSettingModal={openSettingModal}/> */}
         <main className="overflow-hidden">
         <MainMenu />
         <ProfileModal 
          show={profileModalShow}
          onClose={onCloseProfileModal}
          title={"Profile"}
         />
         <SettingModal 
          show={settingModalshow}
          onClose={onCloseSettingModal}
          title={"Setting"}
        />
         {/* <audio ref={audioRef} src={soundSrc} loop={true}/> */}
      </main>
    </>
  );
}

export default MainLayout;
