import React from "react";
import ModalWrapper from "../../Modal/ModalWrapper";
import Text from "../../Wrapper/Text";
import CImage from "../../Img/CImage";
import guestUser from "../../../assets/guestUser.png";
import TextWraper from "../../Wrapper/TextWraper";

function ProfileModal({ show, onClose, title }) {
  return (
    <ModalWrapper
      visible={show}
      onClose={onClose}
      customStyles={{
        width: "25%",
        height: "50%",
      }}
      id="profile-modal"
    >
      <Text title={title} color="text-[#FFB902] text-3xl text-center" />
      <div className=" flex flex-col justify-center items-center py-2 gap-2">
        <CImage width="30" url={guestUser} />
        <Text
          title={"guestUser344"}
          color="text-[#ffffff] text-2xl text-center"
        />
        <div className="profiles flex flex-wrap w-full justify-center gap-x-10 gap-y-4 h-auto mt-4">
          <div>
            <Text
              title={"Played Match"}
              color="profile-text"
            />
            <TextWraper>
              <Text
                title={"1000"}
                color="text-[#ffffff] text-2xl text-center font-semibold"
              />
            </TextWraper>
          </div>
          <div>
            <Text
              title={"Total Win"}
              color="profile-text"
            />
            <TextWraper>
              <Text
                title={"800"}
                color="text-[#ffffff] text-2xl text-center font-semibold"
              />
            </TextWraper>
          </div>
          <div>
            <Text
              title={"Total Percentage"}
              color="profile-text"
            />
            <TextWraper>
              <Text
                title={"80%"}
                color="text-[#ffffff] text-2xl text-center font-semibold"
              />
            </TextWraper>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ProfileModal;
