import React, { useEffect, useState } from "react";
import ModalWrapper from "../../Modal/ModalWrapper";
import Text from "../../Wrapper/Text";
import InputRange from "../../Input/InputRange";
import musicSvg from "../../../assets/music.svg";
import soundSvg from "../../../assets/sound.svg";
import textSvg from "../../../assets/textSvg.svg";
import FlexWrapper from "../../Wrapper/FlexWrapper";
import Button from "../../button/Button";

// import { setSessionStorageItem } from "../../../utils/sessionStorageUtils";
import { gameSound } from "../../../../../../App";


function SettingModal({ show, onClose, title }) {
  const [value, setVolumne] = useState(5);
  const [sound, setSound] = useState(5);

  const local_vol = localStorage.getItem('vol');
  const local_sound = localStorage.getItem('sound');
  

  useEffect(() => {
    if(local_vol) {
      const val = +local_vol / 10;
      gameSound.updateBgVolume(val);
      setVolumne(+local_vol || 5);
    }
    if(local_sound) {
      const val = +local_sound / 10;
      gameSound.updateVolume(val);
      setSound(+local_sound || 5);
    }
  },[local_vol,local_sound])

  //   handle volume
  const handleVolume = (value) => {
    const val = +value / 10;
    gameSound.updateBgVolume(val);
    setVolumne(value);
    localStorage.setItem('vol', value);
  };
  
  const handleSound = (value) => {
    const val = +value / 10;
    gameSound.updateVolume(val);
    // dice_audio.volume = val;
    setSound(value);
    localStorage.setItem('sound', value);
  };

  return (
    <ModalWrapper
      visible={show}
      onClose={onClose}
      customStyles={{
        height: "45%",
        padding: "2rem",
      }}
      id="setting-modal"
    >
      <Text title={title} color="text-[#FFB902] text-4xl text-center" />
      <div className="p-1 my-2 flex flex-col gap-y-3">
        <Text title={"Music"} color="text-[#fcfcfc] text-2xl" />
        <FlexWrapper className="">
          <div className="icon w-8">
            <img src={musicSvg} alt={musicSvg} />
          </div>
          <InputRange value={value} onChange={handleVolume} />
        </FlexWrapper>
      </div>
      <div className="p-1  flex flex-col gap-y-3">
        <Text title={"Sound"} color="text-[#fcfcfc] text-2xl" />
        <FlexWrapper className="">
          <div className="icon w-8">
            <img src={soundSvg} alt={musicSvg} />
          </div>
          <InputRange value={sound} onChange={handleSound} />
        </FlexWrapper>
      </div>
      <div className="w-full flex justify-center items-center pt-6">
        <Button
          text={<img src={textSvg} alt={textSvg} className="w-16" />}
          className="save-buton py-3 px-8 bg-yellow-500 rounded-lg shadow-lg shadow-yellow-500"
          handleClick={onClose}
        />
      </div>
    </ModalWrapper>
  );
}

export default SettingModal;
