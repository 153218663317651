 //  handle update prev state
 export const handleUpdateState = (prev, chunk, stateType) => {
    return prev.map((val) => {
      if (stateType === "token") {
        if (val.color === chunk.color) {
          val.selected = true;
        } else {
          val.selected = false;
        }
      } else if (stateType === "player") {
        if (val.value === chunk.value) {
          val.selected = true;
        } else {
          val.selected = false;
        }
      } else {
        if (val.type === chunk.type) {
          val.selected = true;
        } else {
          val.selected = false;
        }
      }
      return val;
    });
  };