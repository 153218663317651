import React, { useEffect, useState } from "react";
import CardWrapper from "../CardWrapper";

// icons
import checkIcon from "../../../../assets/check.png";
import moneyIcon from "../../../../assets/MoneyLogo.png";
import walletIcon from "../../../../assets/walletIcon.svg";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import Button from "../../../../components/button/Button";
import NavContent from "../../../../components/Wrapper/NavContent";
import Text from "../../../../components/Wrapper/Text";
import { TOKEN } from "../../../../utils/tokenConstant";
import CoinCounterCard from "../CoinCounterCard";
import { useDispatch, useSelector } from "react-redux";
import { getSelecteGameState } from "../../../../helper/game";
import { reset, setGameObj } from "../../../../feature/slice/gameSlice";
import { useHistory } from "react-router-dom";
import Token from "../TokenSelect/Token";
import { handleUpdateState } from "../../../helper";
import { publish, subscribe, unsubscribe } from "../../../../CustomEvents/event";
import { delay, generateRandomRoomName, showToast } from "../../../../utils";
import { createRoom, raiseEvent } from "../../../../constants/socketKeys";
import { getSessionStorageItem, resetSessionStorage, setSessionStorageItem } from "../../../../utils/sessionStorageUtils";
import { socket } from "../../../../socket/socket";
import Spinner from "../../../../components/Spinner/Spinner";


export const CheckIcon = () => {
  return (
    <div>
      <img src={checkIcon} alt={checkIcon} className="animate" />
    </div>
  );
};

function SelectGameModal({ modalShow, onClose, onOpenSelectBoardModal,balance,isCreating,setisCreating }) {
  const [playerCountSelect, setPlayerCountSelect] = useState([
    {
      title: "2 Players",
      selected: true,
      value: 2,
    },
    {
      title: "3 Players",
      selected: false,
      value: 3,
    },
    {
      title: "4 Players",
      selected: false,
      value: 4,
    },
  ]);
  const [tokens, setTokens] = useState([
    {
      color: "green",
      selected: true,
      tokenImage: TOKEN["green"],
      hash: "#00FF19",
    },
    {
      color: "blue",
      selected: false,
      tokenImage: TOKEN["blue"],
      hash: "#00A3FF",
    },
    {
      color: "yellow",
      selected: false,
      tokenImage: TOKEN["yellow"],
      hash: "#F2B901",
    },
    {
      color: "red",
      selected: false,
      tokenImage: TOKEN["red"],
      hash: "#FF0000",
    },
  ]);

  const [gameType, setGameType] = useState([
    // {
    //   type: "money",
    //   value: 0,
    //   selected: false,
    // },
    {
      type: "coin",
      value: 0,
      selected: true,
    },
  ]);
  const { gameMode, gameObj } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const navigate = useHistory();

  /**
   * @param {prev statte}
   */

  // haldle Select Color
  const SelectColor = (token) => {
    dispatch(
      setGameObj({
        token : token?.color,
      })
    );
    setTokens((prev) => handleUpdateState(prev, token, "token"));
  };

  //   handle select player
  const SelectPlayerCount = (player) => {
      dispatch(
        setGameObj({
          inGamePlayer : player?.value,
        })
      )
    setPlayerCountSelect((prev) => handleUpdateState(prev, player, "player"));
  };

  // handle select game type
  const selectGameType = (game) => {
    dispatch(
      setGameObj({
        feeType : game?.type,
        feeAmmount: game?.value,
      })
    )
    setGameType((prev) => handleUpdateState(prev, game, "gameType"));
  };

  // game enm
  const createGame = {
    Multiplayer: () => {
      handleCreateGame(gameMode.name);
    },
    Computer: () => {
      handleCreateGame(gameMode.name);
      setSessionStorageItem('gameObj' , null);
    },
    Private: () => {
      // handleCreateGame(gameMode.name);
      // const { token, inGamePlayer, feeType, feeAmmount } = getSelecteGameState(
      //   playerCountSelect,
      //   tokens,
      //   gameType
      // );
      // dispatchState(token, inGamePlayer, feeType, feeAmmount);
      onOpenSelectBoardModal();
      setPlayerCountSelect([
        {
          title: "2 Players",
          selected: true,
          value: 2,
        },
        {
          title: "3 Players",
          selected: false,
          value: 3,
        },
        {
          title: "4 Players",
          selected: false,
          value: 4,
        },
      ])
    },
  
  };

  // create
  const create = () => {
    if(+balance === 0) {
       showToast('error', "You dont have enough amount to create this game");
       return;
    }
    createGame[gameMode.name]();
  };

  // // ** dispatch state
  // const dispatchState = (token, inGamePlayer, feeType, feeAmmount) => {
  
  // };

  // handle Create game
  const handleCreateGame = (gameMode) => {
    const { token, inGamePlayer, feeType, feeAmmount } = getSelecteGameState(
      playerCountSelect,
      tokens,
      gameType
    );
 
    // create multiplayer
    if (gameMode === "Multiplayer") createMultiplayer(inGamePlayer, gameMode);
    if (gameMode === "Computer") {
      setSessionStorageItem('botGameSession' , null);
      setSessionStorageItem('gameSession' , null);
      dispatch(reset())
      navigate.push("/qwertyuiop/game?started=false");
    }

    // clear storage
    resetSessionStorage();

  };

 

  const createMultiplayer = (inGamePlayer, gameMode) => {
    // ** generate random room name
    const roomName = generateRandomRoomName();

    setisCreating(true);

    // ** publish create room event
    publish(createRoom, {
      roomname: roomName,
      maxPlayers: inGamePlayer,
      ttl: 50,
      isVisible: gameMode === "Multiplayer" ? true : false,
      roomPassword: "",
    });

    //** raise event */
    const roomCreated = {
      roomName: roomName,
      EventCode: 10,
      SendTo: "All",
      CustomData: {
        sendingUser: socket.id,
      },
    };  

    // publis event
    publish(raiseEvent, roomCreated);

    //** clear out session */
    // setSessionStorageItem("gameSession", null);
    // setSessionStorageItem("playerLeft", null);
    
    // navigate.push(`/qwertyuiop/gameEnter?room=${roomName}`);
    // onClose();
  };
  

  // set default valud
  useEffect(() => {
    if(modalShow) {
      dispatch(
      setGameObj({
        token : "green",
        inGamePlayer : 2,
        feeType : '',
        feeAmmount: gameMode?.name === "Private" ? 1 : 0.25,
        selectedBoard: "default",
      })
    );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[gameMode?.name,dispatch, modalShow])

  return (
    <>
      <ModalWrapper
        visible={modalShow}
        onClose={onClose}
        customStyles={{
          width: "60%",
          height: "65%",
        }}
        id="select-game-modal"
        title="create"
      >
        <div className="wrapper-container flex flex-col lg:flex-row h-5/6 overflow-auto xl:overflow-hidden gap-6 lg:p-3">
          <CardWrapper title="Select Pawn">
            <div className="md:px-2 md:mt-5 flex w-full justify-around">
              {tokens.map((token) => (
                <Token
                  token={token}
                  key={token.color}
                  SelectColor={SelectColor}
                />
              ))}
            </div>
          </CardWrapper>
          <CardWrapper title="Select Players">
            <div className="p-2 md:mt-5 flex flex-row lg:flex-col lg:gap-6">
              {playerCountSelect.map((player) => (
                <div
                  className="flex cursor-pointer flex-col-reverse text-center lg:flex-row items-center justify-center gap-3 w-full"
                  key={player.title}
                  onClick={() => SelectPlayerCount(player)}
                >
                  <div
                    className={`w-8 h-8 border-2 ${
                      player.selected
                        ? "border-yellow-500 shadow-md shadow-yellow-500"
                        : ""
                    } rounded-full cursor-pointer`}
                  >
                    {player.selected ? <CheckIcon /> : null}
                  </div>
                  <Text
                    title={player.title}
                    color="text-md md:text-2xl text-white"
                  />
                </div>
              ))}
            </div>
          </CardWrapper>
          <CardWrapper
            title="Select Game"
            customClass={`${gameMode?.name === "Computer" ? "hidden" : ""}`}
          >
            <div className="p-2 xxl:mt-4 flex flex-col gap-3 xl:gap-8">
              {gameType?.map((game) => (
                <div
                  className="flex items-center justify-center gap-5 w-full"
                  key={game.type}
                >
                  {/* <div
                    className={`w-8 h-8 border-2 ${
                      game?.selected
                        ? "border-yellow-500 shadow-md shadow-yellow-500"
                        : ""
                    } rounded-full cursor-pointer`}
                    onClick={() => selectGameType(game)}
                  >
                    {game?.selected ? <CheckIcon /> : null}
                  </div> */}
                  <NavContent icon={walletIcon} text={balance} button={false} />
                </div>
              ))}
              <CoinCounterCard />
            </div>
          </CardWrapper>
        </div>
        <div className="w-full flex justify-end items-center mt-5 xl:mt-0">
          {isCreating && <div className="text-sm flex items-center justify-items-center gap-x-2">
            <div className="scale-75"><Spinner /></div>
            <span>Creating Room...</span>  
          </div>}
          {isCreating === false && (
            <Button
              text={"Create"}
              className={`Create py-3 px-8 text-xl ${
                gameObj?.feeAmmount === 0 && "pointer-events-none opacity-15"
              }`}
              handleClick={create}
            />
          )}
        </div>
      </ModalWrapper>
    </>
  );
}

export default SelectGameModal;
