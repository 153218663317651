import greenToken from '../assets/tokens/green.png';
import greenToken2 from '../assets/tokens/180_green.png';
import greenToken3 from '../assets/tokens/green-90.png';
import greenToken4 from '../assets/tokens/green90.png';

import blueToken from '../assets/tokens/blue.png';
import blueToken2 from '../assets/tokens/180_blue.png';
import blueToken3 from '../assets/tokens/blue-90.png';
import blueToken4 from '../assets/tokens/blue90.png';

import yellowToken from '../assets/tokens/yellow.png';
import yellowToken2 from '../assets/tokens/180_yellow.png';
import yellowToken3 from '../assets/tokens/yellow-90.png';
import yellowToken4 from '../assets/tokens/yellow90.png';

import redToken from '../assets/tokens/red.png';
import redToken2 from '../assets/tokens/180_red.png';
import redToken3 from '../assets/tokens/red-90.png';
import redToken4 from '../assets/tokens/red90.png';

import yellowTile from '../assets/tiles/yellowTile.png';
import redTile from '../assets/tiles/redTile.png';
import blueTile from '../assets/tiles/blueTile.png';
import greenTile from '../assets/tiles/greenTile.png';

import yellowSquare from '../assets/Nature/yellowSquare.png';
import redSquare from '../assets/Nature/redSquare.png';
import blueSquare from '../assets/Nature/blueSquare.png';
import greenSquare from '../assets/Nature/greenSquare.png';

import yellowPolygon from '../assets/Nature/yellowPolygon.png';
import redPolygon from '../assets/Nature/redPolygon.png';
import bluePolygon from '../assets/Nature/bluePolygon.png';
import greenPolygon from '../assets/Nature/greenPolygon.png';

import arrowYellow from '../assets/arrow/arrow-yellow.png';
import arrowRed from '../assets/arrow/arrow-red.png';
import arrowblue from '../assets/arrow/arrow-blue.png';
import arrowGreen from '../assets/arrow/arrow-green.png';


import darrowYellow from '../assets/arrowYellow.svg';
import darrowRed from '../assets/arrowRed.svg';
import darrowblue from '../assets/arrowBlue.svg';
import darrowGreen from '../assets/arrowGreen.svg';



export const TOKEN = {
  'green' : greenToken,
  'red' : redToken,
  'yellow' : yellowToken,
  'blue' : blueToken,
}

export const ROTATED_TOKEN = {
  'green' : greenToken2,
  'red' : redToken2,
  'yellow' : yellowToken2,
  'blue' : blueToken2,
}

export const ROTATED_TOKEN_90 = {
  'green' : greenToken3,
  'red' : redToken3,
  'yellow' : yellowToken3,
  'blue' : blueToken3,
}

export const ROTATED_TOKEN_90_INVERT = {
  'green' : greenToken4,
  'red' : redToken4,
  'yellow' : yellowToken4,
  'blue' : blueToken4,
}

export const TILE = {
  'green' : greenTile,
  'red' : redTile,
  'yellow' : yellowTile,
  'blue' : blueTile,
}

export const ARROW = {
  'green' : arrowGreen,
  'red' : arrowRed,
  'yellow' : arrowYellow,
  'blue' : arrowblue,
}

export const DIRECTION_ARROW = {
    12:darrowGreen,
    69:darrowRed,
    50:darrowblue,
    31:darrowYellow
}

export const NATURE_TILE = {
  'green' : greenSquare,
  'red' : redSquare,
  'yellow' : yellowSquare,
  'blue' : blueSquare,
}

export const NATURE_POLYGON = {
  'green' : greenPolygon,
  'red' : redPolygon,
  'yellow' : yellowPolygon,
  'blue' : bluePolygon,
}

export const ACTIVE_TOKEN = {
  'green-token--active' : greenToken,
  'red-token--active' : redToken,
  'yellow-token--active' : yellowToken,
  'blue-token--active' : blueToken,
}

