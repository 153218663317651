import React from 'react'

function CImage({width="auto",url,coustomClass=""}) {
  return (
    <div className={`max-w-50 min-w-auto w-${width} ${coustomClass} `}>
      <img src={url} alt={url}   draggable={false} />
    </div>
  )
}

export default CImage
