import React from 'react'

function FlexWrapper({children,className}) {
  return (
    <div className={`flex justify-center items-center gap-x-3 ${className}`}>
       {children}
    </div>
  )
}

export default FlexWrapper
