import {io} from "socket.io-client"
import { showToast } from "../utils";

// options
const option = {
  closeOnBeforeunload: false,
  reconnection: false,
    // reconnectionAttempts: 10,
    // reconnectionDelay: 5000,        // Wait 5 seconds between reconnection attempts
    // reconnectionDelayMax: 10000,    // Maximum delay between attempts
    // timeout: 180000,                 // Set to slightly less than server's pingTimeout
};

// // console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
// ** socket client instance
let url = 'https://multiplayerludo.betbyheart.com'
// let url = 'http://localhost:3002'

let socket =  io(url, option);
// let socket =  io(process.env.REACT_APP_LUDO_SOCKET_URL, option);

const tryReconnect = () => {
  setTimeout(() => {
    // showToast("info", 'Reconecting...');
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
}

socket.io.on("close", tryReconnect);

// Log reconnection attempts
socket.on('reconnect_attempt', (attempt) => {
  console.log(`Reconnect attempt #${attempt}`);
  // showToast('info','Reconnecing to server...');
});

socket.on('reconnect', (attempt) => {
  // showToast('success','Reconnected to server...');
  console.log(`Successfully reconnected on attempt #${attempt}`);
  window.location.reload();
});

socket.on('reconnect_failed', () => {
  console.log('Failed to reconnect after multiple attempts');
});

async function sendRequest(key, dataToSend, callback) {
  if(socket) {
    socket.emit(key, dataToSend, callback)
  }
}


// console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
export {sendRequest,socket}



