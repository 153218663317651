import React from 'react'

function TextWraper({children}) {
  return (
    <div className='text-wrapper outline outline-2 outline-[#FFB902] rounded-lg p-3 my-2 w-40'>
      {children}
    </div>
  )
}

export default TextWraper
