// const createRoom ='create_room';
export const SocketcreateRoom = "createRoom";
export const SocketjoinRoom = "joinRoom";
export const SocketCheckMs = "checkMs";

export const ScoketleaveRoom = "leaveRoom";

export const SocketreJoinRoom = "reJoinRoom";
export const SocketOnPlayerEnterRoom = "OnPlayerEnterRoom";
export const SocketOnPlayerLeftRoom = "OnPlayerLeftRoom";
export const SocketPlayerList = "PlayerList";
export const SocketRoomListing = "RoomListing";
export const SocketOnRoomListUpdate = "OnRoomListUpdate";

//Raise Event
export const SocketraiseEvent = "raiseEvent"

//Room Properties
export const SocketsetRoomProperties = "setRoomProperties"
export const SocketgetRoomProperties = "getRoomProperties"

export const setRoomPropertiesForLobby = "setRoomPropertiesForLobby"
export const getRoomPropertiesForLobby = "getRoomPropertiesForLobby"

export const SocketOnRoomPropertiesChange = "OnRoomPropertiesChange";
export const SocketchangeRoomBaseProperty = "changeRoomBaseProperty";



//LocalKeys

// const createRoom ='create_room';
export const OnConnectToMaster = "localOnConnectToMaster_ludo";
export const createRoom = "localCreateRoom_ludo";
export const onCreateRoom = "localOnCreateRoom_ludo";
export const onCreateRoomFailed = "localOnCreateRoomFailed_ludo";

export const joinRoom = "localjoinRoom_ludo";
export const onJoinRoom = "localonjoinRoom_ludo";
export const onJoinRoomFailed = "localonJoinRoomFailed_ludo";

export const reJoinRoom = "localreJoinRoom_ludo";

export const LeaveRoom = "localLeaveRoom_ludo";
export const OnLeaveRoom = "localOnLeaveRoom_ludo";
export const OnLeaveRoomFailed = "localOnLeaveRoomFailed_ludo";

export const OnPlayerEnterRoom = "localOnPlayerEnterRoom_ludo";
export const OnPlayerLeftRoom = "localOnPlayerLeftRoom_ludo";

export const PlayerList = "localPlayerList_ludo";
export const OnPlayerList = "localOnPlayerList_ludo";

export const RoomListing = "localRoomListing_ludo";
export const OnRoomListing = "localOnRoomListing_ludo";
export const OnLocalRoomListUpdate = "OnLocalRoomListUpdate_ludo";

//Raise Event
export const raiseEvent = "localraiseEvent_ludo";
export const onRaiseEvent = "localOnRaiseEvent_ludo";

//Room Properties
export const setRoomProperties = "localsetRoomProperties_ludo";
export const getRoomProperties = "localgetRoomProperties_ludo";
export const onGetRoomProperties = "localonGetRoomProperties_ludo";
export const onLocalGetRoomProperties = "localonGetRoomProperties_ludo";

export const localSetRoomProperties = "localSetRoomProperties_ludo";
export const localGetRoomProperties = "localGetRoomProperties_ludo";
export const OnRoomPropertiesChange = "localOnRoomPropertiesChange_ludo";
export const localchangeRoomBaseProperty = "localchangeRoomBaseProperty_ludo";

export const setLocalWinner = "setLocalWinner_ludo";

//Game Events
export const StartGame = "localStartGame_ludo";


